$(document).ready(function () {
    $(".accordion-card .btn-collapse").on("click", function () {
        if ($(this).hasClass("active")) {
            $(this).removeClass("active").attr("aria-expanded", "false");
            $(this).closest('.accordion-header').siblings(".content-collapse").slideUp(200, function () {
                $(this).addClass("hide");
            });
        } else {
            $(".accordion-card .btn-collapse").removeClass("active");
            $(this).addClass("active").attr("aria-expanded", "true");
            $(".content-collapse").slideUp(200);
            $(this).closest('.accordion-header').siblings(".content-collapse").removeClass("hide")
            .slideDown(200);
        }
    });
});
